
import styled from "styled-components";
import {colors} from "../../res/values/values"
import useWindowWidthAndHeight from "../../utilities/hooks/useWindowWidthAndHeight";
import { useNavigate } from "react-router-dom";
const Container = styled.div`
    display:flex ; 
    flex:1 ;
    flex-direction: column;
    justify-content:center ;
    align-items:center ;
    padding:3rem ;
    @media only screen and (max-width: 768px) {
    padding:0rem ;
    }
    
`;
const Row = styled.div`
    display:flex ; 
    flex-direction:row ;

`;
const Category = styled.div`
    margin:10px ;
    cursor: pointer;
    display:flex ; 
    justify-content:center ;
    align-items:center ;
    @media only screen and (max-width: 768px) {
      width: 100%;
     
  }
`;
const Image = styled.img`
    width: 80%;
    margin-top: 0px;
    object-fit: cover;
  @media only screen and (max-width: 768px) {
    width: 80%;
    max-height: 30vw;
    object-fit: cover;
  }
`;
const Title = styled.h1`
  width: auto;
  margin-top:10px ;
  margin-bottom:10px ;
  font-size: 1.2rem;
  font-weight: 600;
  color: ${colors.darkcolor};
  padding: 5px;
`;
function IntroCategoryContainer() {
  const { width } = useWindowWidthAndHeight();
  const navigate = useNavigate();
  const rootCategory =(catId)=>{
     navigate(`/route=search?${catId ? `&categoryid=${catId}` : ''}`) 
  }
  if (width < 768) {
    return(
      <Container>
          <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
              <Title>OUR CATEGORIES</Title>
          </div>
          <Category onClick={()=>rootCategory(5)}><Image src={`./catalog/Categories/CAN-JAR-PRODUCTS.jpg`}  ></Image></Category>
          <Category onClick={()=>rootCategory(6)}><Image src={`./catalog/Categories/CONFECTIONARY0DNUTS-SEEDS-.jpg`}  ></Image></Category>
          <Category onClick={()=>rootCategory(2)}><Image src={`./catalog/Categories/DAIRY-CHILLED.jpg`}  ></Image></Category>
          <Category onClick={()=>rootCategory(4)}><Image src={`./catalog/Categories/BEERS-SPIRITS.jpg`}  ></Image></Category>
          <Category onClick={()=>rootCategory(9)}><Image src={`./catalog/Categories/PASTA-NOODLES-BREAD0DGRAINS-FLOURS.jpg`}  ></Image></Category>
          <Category onClick={()=>rootCategory(1)}><Image src={`./catalog/Categories/POULTRY-MEAT-FISH.jpg`}  ></Image></Category>
          <Category onClick={()=>rootCategory(8)}><Image src={`./catalog/Categories/SEASONING-HERBS-0DOTHER-AMBIENT.jpg`}  ></Image></Category>
          <Category onClick={()=>rootCategory(7)}><Image src={`./catalog/Categories/TEA-COFFEE-INSTANT-DRINKS.jpg`}  ></Image></Category>
          <Category onClick={()=>rootCategory(11)}><Image src={`./catalog/Categories/FRUIT-VEGETABLES.png`}  ></Image></Category>
          <Category onClick={()=>rootCategory(10)}><Image src={`./catalog/Categories/AMERICAN-PRODUCT.png`}  ></Image></Category>
          <Category onClick={()=>rootCategory(12)}><Image src={`./catalog/Categories/TOYS_CANDIES.png`}  ></Image></Category>
      </Container>
    )
  }else{
    return(
      <Container>
          <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
              <Title>OUR CATEGORIES</Title>
          </div>
        <Row>
            <Category onClick={()=>rootCategory(5)}><Image src={`./catalog/Categories/CAN-JAR-PRODUCTS.jpg`}  ></Image></Category>
            <Category onClick={()=>rootCategory(6)}><Image src={`./catalog/Categories/CONFECTIONARY0DNUTS-SEEDS-.jpg`}  ></Image></Category>
            <Category onClick={()=>rootCategory(2)}><Image src={`./catalog/Categories/DAIRY-CHILLED.jpg`}  ></Image></Category>
        </Row>
        <Row>
            <Category onClick={()=>rootCategory(3)}><Image src={`./catalog/Categories/DRINKS-BEVERAGES.jpg`}  ></Image></Category>
            <Category onClick={()=>rootCategory(4)}><Image src={`./catalog/Categories/BEERS-SPIRITS.jpg`}  ></Image></Category>
            <Category onClick={()=>rootCategory(9)}><Image src={`./catalog/Categories/PASTA-NOODLES-BREAD0DGRAINS-FLOURS.jpg`}  ></Image></Category>
        </Row>
        <Row>
            <Category onClick={()=>rootCategory(1)}><Image src={`./catalog/Categories/POULTRY-MEAT-FISH.jpg`}  ></Image></Category>
            <Category onClick={()=>rootCategory(8)}><Image src={`./catalog/Categories/SEASONING-HERBS-0DOTHER-AMBIENT.jpg`}  ></Image></Category>
            <Category onClick={()=>rootCategory(7)}><Image src={`./catalog/Categories/TEA-COFFEE-INSTANT-DRINKS.jpg`}  ></Image></Category>
        </Row>
        <Row>
            <Category onClick={()=>rootCategory(11)}><Image src={`./catalog/Categories/FRUIT-VEGETABLES.png`}  ></Image></Category>
            <Category onClick={()=>rootCategory(10)}><Image src={`./catalog/Categories/AMERICAN-PRODUCT.png`}  ></Image></Category>
            <Category onClick={()=>rootCategory(12)}><Image src={`./catalog/Categories/TOYS_CANDIES.png`}  ></Image></Category>
        </Row>
      </Container>
    )
  }

}

export default IntroCategoryContainer;
