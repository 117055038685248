// OfferPopUp.jsx
import React from 'react';
import './OfferPopUpStyle.css';

const OfferPopUp = ({ onClose }) => {
    const handleClick = (myLink) => {
        window.open(myLink, '_blank');
        onClose();
    };
  return (
    <div className="popup">
      <div className="popup-content">
        <span className="close" onClick={onClose}>&times;</span>
        <img className="image" src="/catalog/offer.png" alt="Örnek Resim" />
        <button onClick={(_) => handleClick(`${process.env.PUBLIC_URL}/catalog/ProductCatalog/2023EXPOFOODSMIDLANDSCHRISTMASSOFFER.pdf`)} className="button" >View All Offers</button>
      </div>
    </div>
  );
};

export default OfferPopUp;